import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full relative content_video flex justify-center">
        <video
          playsInline
          autoPlay
          muted
          loop
          className="w-full md:h-full object-cover"
        >
          <source src={urlVideo} type="video/mp4" />
        </video>
        <div className="flex flex-col md:w-full mx-auto absolute bottom-[30px] md:bottom-[0px] md:text-start text-center text-white">
          <div className="w-full text-center p-4">
            <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="px-5 md:px-[20%]">
              {rpdata?.dbValues?.[0].description}
            </p>
            <ButtonContent btnStyle="three" />
          </div>
          <div className="md:flex hidden flex-col md:flex-row items-center -mt-10">
            <div className="w-1/4 md:w-[50%] h-[50%] md:ml-80">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/video1-f8800.appspot.com/o/15years.png?alt=media&token=6979b8b5-a118-4c73-94c1-5d0740004220"
                alt="years of experience"
                className="md:w-[390px] w-[200px] md:h-[420px] h-[200px] md:ml-[100px] ml-0 object-contain"
              />
            </div>
            <div className="w-full md:mt-0 -mt-40 md:w-[50%] md:mr-96 h-[50%]">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/video1-f8800.appspot.com/o/sastifaction.png?alt=media&token=bedfa19a-0eaa-44d2-b824-86387650f3ab"
                alt="insignia Google"
                className="md:w-[350px] w-[150px] md:h-[340px] h-[200px] object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroVideo;
